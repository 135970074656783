import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import styled from '@emotion/styled';

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Header from "../components/Header";
import InfoSection from "../components/InfoSection";

import backgroundPortable from "../img/bg-header-small.jpg"
import backgroundNotebook from "../img/bg-header-notebook.jpg"
import backgroundFullHD from "../img/bg-header-fullhd.jpg"
import Contact from "../components/Contact";

const HeaderWrapper = styled("div")`
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-image: url(${backgroundPortable});
  background-size: cover;
  background-position: center center;
  @media screen and (min-width: 1024px) {
    background-image: url(${backgroundNotebook});
  }
  @media screen and (min-width: 1440px) {
    background-image: url(${backgroundFullHD});
  }
  @media screen and (max-width: 550px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 550px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

const ArticleContainer = styled("article")`
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 6.25rem;
  padding-bottom: 0;
  @media screen and (max-width: 550px) {
    padding-top: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

`;

const ArticleContent = styled("article")`

  h2 {
    font-size: 1.2rem;
    color: #000000;
  }

  h3 {
    font-size: 1.1rem;
  }

  p {
    margin-bottom: 1.5rem;
  }

  .gatsby-resp-image-wrapper {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  table {
    width: 100%;
    margin: 2rem 0;
    table-layout: fixed;
    text-align: left;
    border-collapse: collapse;

    td, th {
      border: 1px solid #dee2e6;
      padding: .5rem;
      vertical-align: bottom;
      word-break: break-word;
    }
  }

  ul {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  p + ul {
    margin-top: -.75em;
  }
`;

const ContactHeading = styled("h2")`
  margin-top: 1em;
  font-size: 1.4rem;
`;

const Attachments= styled("div")`

  a {
    text-decoration: none;
    &:hover span {
      text-decoration: none;
    }
    span {
      text-decoration: underline;
    }
  }

  a[href$=".pdf"]::after {
    content: " (PDF)";
    color: #666666;
  }

  a[href$=".docx"]::after {
    content: " (Docx)";
    color: #666666;
  }

  a[href$=".doc"]::after {
    content: " (Doc)";
    color: #666666;
  }

  a[href$=".png"]::after {
    content: " (PNG)";
    color: #666666;
  }

  a[href$=".jpg"]::after {
    content: " (JPG)";
    color: #666666;
  }
`

export const BlogPostTemplate = ({
  content,
  contentComponent,
  title,
  helmet,
  date,
  files
}) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      {helmet || ""}
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <ArticleContainer>
        <ArticleContent>
          <h1>{title}</h1>
          <p>
            <em>Institut liberálních studií, tisková zpráva ze dne {date}</em>
          </p>
          <PostContent content={content} />
        </ArticleContent>
        <ContactHeading>Pro více informací kontaktujte:</ContactHeading>
        <Contact />
        {files && files.length ? (
          <Attachments>
            <hr />
            <h3>Přílohy</h3>
            <ul>
              {files.map(file =>
                <li>
                  <a target="_blank" rel="noopener noreferrer" download={file.filename + file.file.extension} href={file.file.publicURL}><span>{file.filename}</span></a>
                </li>)}
            </ul>
          </Attachments>
        ) : null}
      </ArticleContainer>
      <InfoSection />
    </>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  date: PropTypes.string,
  files: PropTypes.array
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        files={post.frontmatter.files}
        helmet={
          <Helmet titleTemplate="%s | Den daňové svobody">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={post.frontmatter.title} />
            <meta property="og:description" content={post.frontmatter.description} />
            <meta name="twitter:title" content={post.frontmatter.title} />
            <meta name="twitter:description" content={post.frontmatter.description} />
          </Helmet>
        }
        title={post.frontmatter.title}
        date={post.frontmatter.date}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "DD. MM. YYYY")
        title
        description
        files {
          file {
            extension
            publicURL
          }
          filename
        }
      }
    }
  }
`;
